<script>
import { defineProps } from "vue";

const props = defineProps({
  params: Object,
});
</script>

<template>
  <v-chip
    v-if="params.data.order_status"
    :color="params.data.order_status.color"
    size="small"
  >
    {{ params.value }}
  </v-chip>
</template>

<style scoped></style>
